<template>
  <b-row class="hp-authentication-page">
    <left-content />

    <b-col cols="12" lg="6" class="py-sm-64 py-lg-0">
      <b-row align-v="center" align-h="center" class="h-100 mx-4 mx-sm-n32">
        <b-col
          cols="12"
          md="9"
          xl="7"
          class="col-xxxl-5 px-8 px-sm-0 pt-24 pb-48"
        >
          <h1 class="mb-0 mb-sm-24">Login</h1>
          <p class="mt-sm-8 mt-sm-0 text-black-60">
            This is the backup login, in case the default Discord login doesn't
            work.
          </p>

          <b-alert variant="warning" show>
            <h4 class="mb-6 text-warning">
              <strong> Remember </strong>
            </h4>
            <p class="mb-0">
              You need to <a href="/register">create an account</a> before you
              can login.
            </p>
          </b-alert>

          <form name="form" @submit.prevent="handleLogin">
            <div class="form-group">
              <label for="username">Username</label>
              <input
                v-model="user.username"
                v-validate="'required'"
                type="text"
                class="form-control"
                name="username"
              />
              <div
                v-if="errors.has('username')"
                class="alert alert-danger"
                role="alert"
              >
                Username is required!
              </div>
            </div>
            <div class="form-group">
              <label for="password">Password</label>
              <input
                v-model="user.password"
                v-validate="'required'"
                type="password"
                class="form-control"
                name="password"
              />
              <div
                v-if="errors.has('password')"
                class="alert alert-danger"
                role="alert"
              >
                Password is required!
              </div>
            </div>
            <div class="form-group">
              <button class="btn btn-primary btn-block" :disabled="loading">
                <span
                  v-show="loading"
                  class="spinner-border spinner-border-sm"
                ></span>
                <span>Login</span>
              </button>
            </div>
            <div class="form-group">
              <div v-if="message" class="alert alert-danger" role="alert">
                {{ message }}
              </div>
            </div>
          </form>

          <div class="hp-form-info">
            <span class="text-black-80 hp-text-color-dark-40 hp-caption mr-4">
              Don’t you have an account?
            </span>

            <b-link
              class="text-primary-1 hp-text-color-dark-primary-1 hp-caption"
              to="/register"
            >
              Create an account
            </b-link>
          </div>

          <div class="hp-other-links mt-24">
            <a
              href="./legal/privacy-policy"
              class="text-black-80 hp-text-color-dark-40"
            >
              Privacy Policy
            </a>

            <a
              href="./legal/terms-of-use"
              class="text-black-80 hp-text-color-dark-40"
            >
              Terms of use
            </a>

            <a
              href="https://undisputednoobs.freshdesk.com/support/home"
              target="_blank"
              class="text-black-80 hp-text-color-dark-40"
            >
              I need help
            </a>
          </div>
        </b-col>
      </b-row>
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
  BButton,
  BLink,
  BForm,
  BFormGroup,
  BFormInput,
  BFormCheckbox,
  BSpinner,
  BAlert,
} from "bootstrap-vue";
import LeftContent from "../LeftContent.vue";
import User from "../../../../models/user";
import discordServices from "../../../../services/discord.services";

export default {
  components: {
    BRow,
    BCol,
    BButton,
    BLink,
    BForm,
    BFormGroup,
    BFormInput,
    BFormCheckbox,
    BSpinner,
    LeftContent,
    BAlert,
  },
  data() {
    return {
      user: new User("", ""),
      loading: false,
      message: "",
      needBackupMethod: false,
    };
  },
  computed: {
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
  created() {
    if (this.loggedIn) {
      this.$router.push("/");
    } else {
      //console.log("Not logged in");
    }
  },
  methods: {
    handleLogin() {
      this.loading = true;
      this.$validator.validateAll().then((isValid) => {
        if (!isValid) {
          this.loading = false;
          return;
        }

        if (this.user.username && this.user.password) {
          this.$store.dispatch("auth/login", this.user).then(
            () => {
              window.location.reload();
            },
            (error) => {
              this.loading = false;
              this.message =
                (error.response &&
                  error.response.data &&
                  error.response.data.message) ||
                error.message ||
                error.toString();
            }
          );
        }
      });
    },
    discordLogin() {
      const redirectURL = {
        prod: "https://discord.com/api/oauth2/authorize?client_id=1003403624975695932&redirect_uri=https%3A%2F%2Fmember.undisputednoobs.com%2Flogin&response_type=code&scope=identify%20guilds.members.read",
        dev: "https://discord.com/api/oauth2/authorize?client_id=1003403624975695932&redirect_uri=http%3A%2F%2Flocalhost%3A8080%2Flogin&response_type=code&scope=identify%20guilds.members.read",
      };

      window.location.href = redirectURL.prod;
    },
    loadingDiscord() {
      // Use a shorter name for this.$createElement
      const h = this.$createElement;
      // Increment the toast count
      this.count++;
      // Create the message
      const vNodesMsg = h("p", { class: ["text-center", "mb-0"] }, [
        " Retrieving your Discord data... Please wait... ",
        h("b-spinner", { class: ["align-middle"] }),
      ]);
      // Create the title
      const vNodesTitle = h(
        "div",
        { class: ["d-flex", "flex-grow-1", "align-items-baseline", "mr-2"] },
        [
          h("strong", { class: "mr-2" }, "Account Registration"),
          h("small", { class: "ml-auto text-italics" }, "Just now"),
        ]
      );
      // Pass the VNodes as an array for message and title
      this.$bvToast.toast([vNodesMsg], {
        title: [vNodesTitle],
        autoHideDelay: 500,
      });
    },
    showLogin() {
      this.needBackupMethod = true;
    },
  },
  mounted() {
    if (this.$route.query.code) {
      this.loadingDiscord();
      const code = this.$route.query.code;
      discordServices.Login(code).then(
        (res) => {
          if (res.success) {
            if (res.data.portalRole != "user") {
              window.location.href = "/admin";
            } else {
              window.location.reload();
            }
          } else {
            this.$bvToast.toast(res.message, {
              title: "Error",
              variant: "danger",
              autoHideDelay: 5000,
            });

            if (res.notRegistered) {
              this.$router.push("/register?error=noAccount");
            }
          }
        },
        (error) => {
          this.loading = false;
          this.message =
            (error.response &&
              error.response.data &&
              error.response.data.message) ||
            error.message ||
            error.toString();
        }
      );

      this.$router.replace({
        path: this.$route.path,
        query: {},
      });
    }
  },
};
</script>

<style scoped>
label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}
</style>
